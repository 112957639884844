import React from 'react'
import { graphql } from 'gatsby'
import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import {SectionContainer, Img, TwoColumnGrid,
  GridLeft, GridRight
} from '~/utils/styles'
import {ProductTitle, ProductDescription, Scroll, 
	Pricer, Wrapper, PriceArea
} from './styles'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  return (
    <>
      <SEO title={product.title} description={product.description} />
      <Wrapper>
        <SectionContainer>
          <TwoColumnGrid>
            <GridLeft>
              {product.images.map(image => (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  key={image.id}
                  alt={product.title}
                  src={image.src}
                />
      ))}
            </GridLeft>
            <GridRight>
              <ProductTitle>{product.title}</ProductTitle>
              <PriceArea>
                <Pricer>${product.priceRange.maxVariantPrice.amount}</Pricer>
                <afterpay-placement 
                  data-locale="en_US"
                  data-currency="USD" 
                  data-amount={product.priceRange.maxVariantPrice.amount}
                ></afterpay-placement>
              </PriceArea>
      <hr />
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
      <hr />
      <Scroll>
        <p>Scroll up/down to see pictures of your options</p>
      </Scroll>
              <ProductForm product={product} />
            </GridRight>
          </TwoColumnGrid>
        </SectionContainer>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
		image {
			originalSrc
			id
			localFile {
			  childImageSharp {
				fluid(maxWidth: 910) {
				  ...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			  }
			}
		}
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
    }
  }
`

export default ProductPage
