import styled from '@emotion/styled'
import {breakpoints} from '~/utils/styles'

export const Wrapper = styled.div`
  margin: 0 auto;
  @media (min-width: ${breakpoints.l}px){
    margin-top: 80px;
  }
`
export const PriceArea = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ProductTitle = styled.h1`
  font-family: 'Fjalla One', sans-serif;
  font-size: 4rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  text-transform: uppercase; 
  letter-spacing: 2px;
`
export const Pricer = styled.h1`
  color: #dc3545;
  @media (max-width: ${breakpoints.xs}px){
    font-size: 36px;
  }
`
export const ProductDescription = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
`
export const Scroll = styled.div`
	margin-top: 20px;
	& p {
		font-family: Questrial;
	}
`